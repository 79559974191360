import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./ti-header.scss"
import CustomMarkdown from "../../utils/customMarkdown"
import { GatsbyImage } from "gatsby-plugin-image"

//header icons
import car from "../../assets/ti_car_block.svg";
import van from "../../assets/ti_van_block.svg";
import bike from "../../assets/ti_bike_block.svg";
import motorhome from "../../assets/ti_motorhome_block.svg";
import caravan from "../../assets/ti_caravan_block.svg";
import car_hover from "../../assets/ti_hovercar_block.svg";
import van_hover from "../../assets/ti_hovervan_block.svg";
import bike_hover from "../../assets/ti_hoverbike_block.svg";
import motorhome_hover from "../../assets/ti_hovermotorhome_block.svg";
import caravan_hover from "../../assets/ti_hovercaravan_block.svg";

//fur
import pink_fur from "../../assets/ti_fur_pink.svg";
import blue_fur from "../../assets/ti_fur_blue.svg";

//popup
import car_logo from "../../assets/ti_car_logo.svg";
import van_logo from "../../assets/ti_van_logo.svg";
import bike_logo from "../../assets/ti_bike_logo.svg";
import motorhome_logo from "../../assets/ti_motorhome_logo.svg";
import caravan_logo from "../../assets/ti_caravan_logo.svg";
import carPopup from "../../assets/ti_car_white.svg";
import vanPopup from "../../assets/ti_van_white.svg";
import bikePopup from "../../assets/ti_bike_white.svg";
import motorhomePopup from "../../assets/ti_motorhome_white.svg";
import caravanPopup from "../../assets/ti_caravan_white.svg";
import car_pink_popup from "../../assets/ti_car_pink.svg";
import van_pink_popup from "../../assets/ti_van_pink.svg";
import bike_pink_popup from "../../assets/ti_bike_pink.svg";
import motorhome_pink_popup from "../../assets/ti_motorhome_pink.svg";
import caravan_pink_popup from "../../assets/ti_caravan_pink.svg";

//animation
import SaturateImage from "../saturate-image/saturate-image"
import SparkleImage from "../sparkle-image/sparkle-image"


const TIHeader = ({ UpperSubtitle, MiddleText, Title, Text, CardBgColor, SecondColumnType, Image, ImagePosition, BackgroundColor, EnableMask }) => {

    const data = useStaticQuery(graphql`
        {
            strapiSiteSettings {
                CarQuoteLink
                VanQuoteLink
                MotorbikeQuoteLink
                MotorhomeQuoteLink
                CaravanQuoteLink
            }
        }
        `)

    const [ctaBarVisible, setCtaBarVisibility] = useState(false)

    function showHideCta() {
        var y = window.scrollY;
        if (y >= 400) {
            setCtaBarVisibility(true)
        } else {
            setCtaBarVisibility(false)
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", showHideCta)
    }, [])

    const [switchImage, setSwitchImage] = useState(false)
    const [product, setProduct] = useState(null)

    const onHover = (element) => {
        setProduct(element)
        setSwitchImage(true);
    };
    const onLeave = () => {
        setProduct(null)
        setSwitchImage(false);
    };

    const [showPopup, setShowPopup] = useState('')
    const [insurerURL, setInsurerURL] = useState('')

    const [switchImagePopup, setSwitchImagePopup] = useState(false)

    const onHoverPopup = () => {
        setSwitchImagePopup(true);
    };
    const onLeavePopup = () => {
        setSwitchImagePopup(false);
    };

    return (
        <>
            {
                Text != null ? //'Text' field -> usually for buttons markown
                    ctaBarVisible ?
                        <div uk-scrollspy="cls: uk-animation-fade; target: .ti-nav-cta-wrapper; delay: 300; repeat: true">
                            <div className="ti-nav-cta-wrapper uk-hidden@m" data-uk-sticky="offset: 60" id="nav-top">
                                <div className="ti-nav-cta-container ">
                                    <a className="ti-nav-cta-container-primary-cta" href="https://quotes.theinsurers.co.uk/car/default/">
                                        Retrieve a quote
                                    </a>
                                    <a className="ti-nav-cta-container-secondary-cta" href="https://quotes.theinsurers.co.uk/car/aboutyou/">
                                        Compare insurance
                                    </a>
                                </div>
                            </div>
                        </div>
                        : ""
                    : ""

            }

            <div className={`ti-header-container ${BackgroundColor == 'DarkPink' || !BackgroundColor ? 'header-dark-pink' : 'header-light-pink'}`}>
                <div className="header-content uk-child-width-1-2@m uk-child-width-1-1@s">

                    {
                        (SecondColumnType && SecondColumnType != "Quotes" && ImagePosition == "left" &&
                            <div className={MiddleText ? "second-column light-theme" : "second-column light-theme"}>
                                <div className="icon-container ti-padding-bottom">
                                    {
                                        SecondColumnType == "MonsterSaturate" ?
                                            <SaturateImage imageData={Image} />
                                            :
                                            SecondColumnType == "MonsterSparkle" ?
                                                <SparkleImage imageData={Image} />
                                                :
                                                <GatsbyImage image={Image?.gatsbyImageData} alt="Icon-Image-Section" style={{ maxWidth: '350px' }}></GatsbyImage>
                                    }
                                </div>
                            </div>)
                    }

                    <div className={` ${CardBgColor == 'Empty' || !CardBgColor ? "light-theme" : "dark-theme"}`} id={(!UpperSubtitle && !MiddleText && !Text) ? "main-title-section" : ""}>
                        <div>
                            {UpperSubtitle ? <h3 className="upper-subtitle">{UpperSubtitle}</h3> : ""}
                            {MiddleText ? <span className="large-text">{MiddleText}</span> : ""}

                            <div className={(!UpperSubtitle && !MiddleText && !Text) ? "main-title-only" : ""}>
                                <h1 className="main-title">{Title}</h1>
                            </div>

                            {
                                Text ?
                                    <div className="markdown-text-section">
                                        <CustomMarkdown className="" children={Text}></CustomMarkdown>
                                    </div>
                                    : ""
                            }
                        </div>
                    </div>

                    {
                        SecondColumnType == "Quotes" ?
                            <div className="second-column light-theme">
                                <div>
                                    <h3 style={{ marginBottom: "12px" }}>Compare insurance for my...</h3>
                                    <div className="quotes-container" data-uk-grid>
                                        <div className="quote-section" onMouseEnter={() => onHover('car')} onMouseLeave={onLeave}>
                                            <a href={data.strapiSiteSettings.CarQuoteLink}>
                                                <img src={switchImage && product == 'car' ? car_hover : car} className="quote-icon" />
                                                {/* <p>Car</p> */}
                                            </a>
                                        </div>
                                        <div className="quote-section" onMouseEnter={() => onHover('van')} onMouseLeave={onLeave}>
                                            <a href="#" onClick={() => { setShowPopup('van'); setInsurerURL(data.strapiSiteSettings.VanQuoteLink) }}>
                                                <img src={switchImage && product == 'van' ? van_hover : van} className="quote-icon" />
                                                {/* <p>Van</p> */}
                                            </a>
                                        </div>
                                        <div className="quote-section" onMouseEnter={() => onHover('bike')} onMouseLeave={onLeave}>
                                            <a href="#" onClick={() => { setShowPopup('bike'); setInsurerURL(data.strapiSiteSettings.MotorbikeQuoteLink) }}>
                                                <img src={switchImage && product == 'bike' ? bike_hover : bike} className="quote-icon" />
                                                {/* <p>Motorbike</p> */}
                                            </a>
                                        </div>
                                        <div className="quote-section" onMouseEnter={() => onHover('motorhome')} onMouseLeave={onLeave}>
                                            <a href="#" onClick={() => { setShowPopup('motorhome'); setInsurerURL(data.strapiSiteSettings.MotorhomeQuoteLink) }}>
                                                <img src={switchImage && product == 'motorhome' ? motorhome_hover : motorhome} className="quote-icon" />
                                                {/* <p>Motorhome</p> */}
                                            </a>
                                        </div>
                                        {/* <div className="quote-section" onMouseEnter={() => onHover('caravan')} onMouseLeave={onLeave}>
                                            <a href="#" onClick={() => { setShowPopup('caravan'); setInsurerURL(data.strapiSiteSettings.CaravanQuoteLink) }}>
                                                <img src={switchImage && product == 'caravan' ? caravan_hover : caravan} className="quote-icon" />
                                              
                                            </a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            : ""
                    }

                    {
                        (SecondColumnType && SecondColumnType != "Quotes" && ImagePosition == "right" &&
                            <div className={MiddleText ? "second-column light-theme" : "second-column light-theme"}>
                                <div className="icon-container ti-padding-top">
                                    {
                                        SecondColumnType == "MonsterSaturate" ?
                                            <SaturateImage imageData={Image} />
                                            :
                                            SecondColumnType == "MonsterSparkle" ?
                                                <SparkleImage imageData={Image} />
                                                :
                                                <GatsbyImage image={Image?.gatsbyImageData} alt="Icon-Image-Section" style={{ maxWidth: '350px' }}></GatsbyImage>
                                    }
                                </div>
                            </div>)
                    }

                </div>

            </div>

            {
                EnableMask ?
                    EnableMask == "Pink" ?
                        <div className="pink-fur">
                            <img src={pink_fur} />
                        </div>
                        :
                        EnableMask == "Blue" ?
                            <div className="blue-fur">
                                <img src={blue_fur} />
                            </div>
                            : ""
                    : ""
            }

            {
                Text != null ? //'Text' field -> usually for buttons markown
                    ctaBarVisible ?
                        <div className="nav-bottom" uk-scrollspy="cls: uk-animation-fade; target: .ti-nav-cta-wrapper; delay: 300; repeat: true">
                            <div className="ti-nav-cta-wrapper">
                                <div className="ti-nav-cta-container ">
                                    <a className="ti-nav-cta-container-primary-cta" href="https://quotes.theinsurers.co.uk/car/default/">
                                        Retrieve a quote
                                    </a>
                                    <a className="ti-nav-cta-container-secondary-cta" href="https://quotes.theinsurers.co.uk/car/aboutyou/">
                                        Compare insurance
                                    </a>
                                </div>
                            </div>
                        </div>
                        : ""
                    : ""
            }

            {showPopup != '' ?
                <div className="popup-overlay-for-ti" uk-scrollspy="cls: uk-animation-fade; target: .popup; delay: 10; repeat: true">
                    <div className="popup">
                        <span className="close" onClick={() => setShowPopup('')}>&times;</span>
                        <div className="popup-content">
                            <img className="insurer-logo"
                                src={showPopup == "car" ? car_logo :
                                    showPopup == "van" ? van_logo :
                                        showPopup == "bike" ? bike_logo :
                                            showPopup == "motorhome" ? motorhome_logo :
                                                showPopup == "caravan" ? caravan_logo : ""} />

                            <p>You are about to go to The
                                {showPopup == "car" ? " Car " :
                                    showPopup == "van" ? " Van " :
                                        showPopup == "bike" ? " Bike " :
                                            showPopup == "motorhome" ? " Motorhome " :
                                                showPopup == "caravan" ? " Caravan " : ""}
                                Insurer</p>

                            <a className="cta" href={insurerURL != '' ? insurerURL : ""} onMouseEnter={onHoverPopup} onMouseLeave={onLeavePopup}>
                                <img
                                    className="mini-logo"
                                    src={(showPopup == "car" && switchImagePopup) ? car_pink_popup :
                                        showPopup == "car" ? carPopup :
                                            (showPopup == "van" && switchImagePopup) ? van_pink_popup :
                                                showPopup == "van" ? vanPopup :
                                                    (showPopup == "bike" && switchImagePopup) ? bike_pink_popup :
                                                        showPopup == "bike" ? bikePopup :
                                                            (showPopup == "motorhome" && switchImagePopup) ? motorhome_pink_popup :
                                                                showPopup == "motorhome" ? motorhomePopup :
                                                                    (showPopup == "caravan" && switchImagePopup) ? caravan_pink_popup :
                                                                        showPopup == "caravan" ? caravanPopup : ""} />
                                <b>Let's go!</b>
                            </a>
                            <p className="span-text" onClick={() => setShowPopup('')}>Stay at The Insurers</p>
                        </div>
                    </div>
                </div>
                : ""
            }
        </>
    )
}

export default TIHeader